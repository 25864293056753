import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import {createBlogAPi, GetBlogAPi} from '../../services/blog-service/blog.api';
import {toast} from 'react-toastify';
import {DeleteContactAPI, GetContactAPI} from '../../services/contact/contact.service';

export const CONTACT_KEY = 'contact';

export const CONTACT_INITIAL_STATE = {
    contactList: [],
    isLoading: false
};

export const getContactData = createAsyncThunk(
    'contact/getContactData',
    async ( _, thunkAPI ) => {
        const response = await GetContactAPI();
        if ( response ) {
            return response;
        }
        if ( !response.length ) {
            return thunkAPI.rejectWithValue('error' );
        }
    }
);

export const contactDelete = createAsyncThunk(
    'contact/contactDelete',
    async (payload, _, thunkAPI ) => {
        const response = await DeleteContactAPI(payload);
        if ( response ) {
            return response;
        }
        if ( !response.length ) {
            return thunkAPI.rejectWithValue('error' );
        }
    }
);


export const contactSlice = createSlice({
    name: CONTACT_KEY,
    initialState: CONTACT_INITIAL_STATE,
    reducers: {},
    extraReducers: (builder) =>{
        builder
            .addCase(getContactData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getContactData.fulfilled, ( state, action ) => {
                state.contactList = action.payload;
                state.isLoading = false;
            } )
            .addCase(getContactData.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(contactDelete.fulfilled, ( state, action ) => {
                state.contactList = action.payload;
                state.isLoading = false;
            } )
            .addCase(contactDelete.pending, ( state, action ) => {
                state.isLoading = true;
            } )
        ;
    }
});

export const contactReducer = contactSlice.reducer;

export const getContact = (rootState) => rootState[CONTACT_KEY];
export const selectContact = createSelector(getContact, (state) => state.contactList);
export const selectIsLoading = createSelector(getContact, (state) => state.isLoading);
