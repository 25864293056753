export const HEADER_LINKS = [
    {
        label: 'Shop',
        link: '/shop'
    },
    {
        label: 'Blogs',
        link: '/blogs'
    },
    {
        label: 'About Us',
        link: '/about-us'
    },
]

export const AUTHENTICATED_HEADER_LINKS = [
    {
        label: 'Shop',
        link: '/shop'
    },
    {
        label: 'Blogs',
        link: '/blogs'
    },
    {
        label: 'Create Product',
        link: '/create'
    }, {
        label: 'Write',
        link: '/write'
    },
    {
        label: 'Order Table',
        link: '/orders'
    },
    {
        label: 'Contact Table',
        link: '/contact'
    },
    // {
    //     label: 'Feedback',
    //     link: '/feedback'
    // }
]
