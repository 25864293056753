export const DUMMY_SWAPPER = [
    {
        id: 1,
        image: 'https://images.unsplash.com/photo-1550623685-2227f7bbef18?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8c2FsdCUyMGJhdGh8ZW58MHx8MHx8fDA%3D'
    },
    {
        id: 2,
        image: 'https://images.unsplash.com/photo-1626897845060-c4c89a918f51?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fHNhbHQlMjBiYXRofGVufDB8fDB8fHww'
    }
];

export const DUMMY_IMAGE = 'https://images.unsplash.com/photo-1550623685-2227f7bbef18?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8c2FsdCUyMGJhdGh8ZW58MHx8MHx8fDA%3D'
