function BlogLoader() {
    return(
        <div className='w-full md:w-[75%] xl:w-[50%] animate-pulse'>
				<div className='w-[400px] h-[10px] rounded bg-disabled opacity-50'/>
            <div className='flex flex-col mt-[12px]'>
                <div className='w-[500px] h-[10px] rounded bg-disabled opacity-50'/>
                <div className='mt-[24px] md:h-[440px] h-[220px] w-full bg-disabled rounded opacity-50' />
                <div className='flex gap-[16px] md:px-[12px] mt-[8px]'>
                    <div className='w-[100px] h-[10px] rounded bg-disabled opacity-50'/>
                    <div className='w-[150px] h-[10px] rounded bg-disabled opacity-50'/>
                    <div className='w-[100px] h-[10px] rounded bg-disabled opacity-50'/>

                </div>
                <div className='flex flex-col gap-[12px] mt-[24px]'>
                    <div className='w-[500px] h-[10px] rounded bg-disabled opacity-50'/>
                    <div className='w-[560px] h-[10px] rounded bg-disabled opacity-50'/>
                    <div className='w-[500px] h-[10px] rounded bg-disabled opacity-50'/>
                    <div className='w-[590px] h-[10px] rounded bg-disabled opacity-50'/>
                    <div className='w-[500px] h-[10px] rounded bg-disabled opacity-50'/>
                    <div className='w-[560px] h-[10px] rounded bg-disabled opacity-50'/>
                    <div className='w-[500px] h-[10px] rounded bg-disabled opacity-50'/>
                    <div className='w-[590px] h-[10px] rounded bg-disabled opacity-50'/>

                </div>
                <div className='flex flex-col gap-[12px] mt-[28px]'>
                    <div className='w-[500px] h-[10px] rounded bg-disabled opacity-50'/>
                    <div className='w-[560px] h-[10px] rounded bg-disabled opacity-50'/>
                    <div className='w-[500px] h-[10px] rounded bg-disabled opacity-50'/>
                    <div className='w-[590px] h-[10px] rounded bg-disabled opacity-50'/>
                    <div className='w-[500px] h-[10px] rounded bg-disabled opacity-50'/>
                    <div className='w-[560px] h-[10px] rounded bg-disabled opacity-50'/>
                    <div className='w-[500px] h-[10px] rounded bg-disabled opacity-50'/>
                    <div className='w-[590px] h-[10px] rounded bg-disabled opacity-50'/>

                </div>
            </div>
        </div>
    )
}

export default BlogLoader;
