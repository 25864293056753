export const addItem = (cart, item) => {
    const existingCartItem = cart.find((cartItem ) => item.id === cartItem.id);
    if ( existingCartItem ) {
        return cart.map( ( cartItem ) =>
            cartItem?.id === item?.id
                ? {
                    ...cartItem,
                    quantity: cartItem.quantity + 1,
                }
                : cartItem
        );
    }
    return [...cart, { ...item, quantity: 1 }];
};

export const getPrice = (alt, price) => {
    if ( alt !== '0.0' && alt ) {
        return alt;
    } else {
        return price;
    }
};

export const removeItem = (cart, payload) => cart.map((cartItem ) => cartItem.id === payload.id ? {
    ...cartItem,
    quantity: cartItem.quantity - 1
} : cartItem);

export const removeItems = (cart, payload) => cart.filter((cartItem ) => cartItem.id !== payload.id);
