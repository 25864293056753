import {useEffect, useState} from 'react';
import {FetchSingleProduct} from '../../services/product-service/product.api';
import {useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {addToCart} from '../../redux/cart/cart.slice';
import ProductLoader from './product-loader';

function ProductDetails() {
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(false)
    const id = useParams()['id']
    const dispatch = useDispatch();
    useEffect(() => {
        const getProduct = async () => {
            setLoading(true)
            const data = await FetchSingleProduct(id);
            setProduct(data)
            setLoading(false)
        }
        getProduct();
    }, []);

    return(
        <div className='px-[24px] xl:px-[122px] my-[48px]'>
            {
                loading ? <ProductLoader /> : (
                    <div className='flex flex-col md:flex-row'>
                        <div className='md:h-[440px] md:w-[440px]'>
                            <img src={product?.image} alt='dummy' className='object-contain h-full w-full rounded-[8px]' />
                        </div>
                        <div className='w-full mt-[24px] md:ml-[24px] lg:w-[30%]'>
                            <span className='font-bold text-typography-700'>{product?.title}</span>
                            <div className='flex flex-col mt-[12px]'>
                                <span className='font-light text-primary'> {product?.brand} </span>
                                <span className='mt-[8px] font-bold'>NU {product?.price}</span>
                                <span className='text-typography-700 font-light'>(Excluding all taxes)</span>
                            </div>
                            <div className='flex flex-col mt-[24px]'>
                                <span className='font-light'> Product Details</span>
                                <span className='font-light mt-[12px] text-typography-700 text-[14px]'>
							{product?.description}
						</span>
                            </div>
                            <button className='bg-primary text-white mt-[28px] px-[12px] py-[8px] rounded-[8px] text-light'
                                    onClick={() => dispatch(addToCart(product))}>
                                Add to Cart
                            </button>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default ProductDetails;
