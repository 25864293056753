import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Mousewheel, Pagination } from 'swiper/modules';
import {DUMMY_SWAPPER} from '../../../shared/constant/shared.constant';

function LandingSwapper() {
    return (
        <Swiper
            direction={'vertical'}
            slidesPerView={1}
            spaceBetween={30}
            mousewheel={false}
            style={{ height: '100%' }}
            pagination={{
                clickable: true,
            }}
            modules={[Mousewheel, Pagination]}
        >
            {
                DUMMY_SWAPPER.map(({ id, image }) => (
                    <SwiperSlide key={id}>
                        <img src={image}
                             alt='logo'
                             className='h-full w-full object-cover'
                        />
                    </SwiperSlide>
                ))
            }
        </Swiper>
    )
}

export default LandingSwapper;
