import noData from '../../assets/image/no-data-pana.png';
import {useDispatch} from 'react-redux';
import {useEffect, useState} from 'react';
import {FetchOrderAPI} from '../../services/order/order.service';
import {useNavigate} from 'react-router-dom';

function Order() {
    const [orders, setOrder] = useState([]);
    const route = useNavigate();
    useEffect(() => {
        FetchOrderAPI().then((res) => {
            setOrder(res)
        })
    }, [])
    return(
        <div className='flex flex-col md:flex-row px-[24px] xl:px-[122px] my-[40px] gap-[12px] lg:gap-[38px]'>
            <div className='border border-secondary p-[12px] md:p-[24px]  rounded-[8px] w-full md:w-full'>
                {
                    !orders.length ?
                        <div className='flex w-full justify-center items-center'>
                            <div className=' w-[250px] h-[250px]'>
                                <img src={noData} alt='no data' className='opacity-60'/>
                            </div>
                        </div>:
                        <div className="flex flex-col">
                            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                                    <div className="overflow-hidden">
                                        <table className="min-w-full text-left text-sm font-light">
                                            <thead className="border-b font-medium dark:border-neutral-500">
                                            <tr>
                                                <th scope="col" className="px-6 py-4">OrderId</th>
                                                <th scope="col" className="px-6 py-4">Date</th>
                                                <th scope="col" className="px-6 py-4">Email</th>
                                                <th scope="col" className="px-6 py-4">Name</th>
                                                <th scope="col" className="px-6 py-4">Phone</th>
                                                <th scope="col" className="px-6 py-4">Address</th>
                                                <th scope="col" className="px-6 py-4">Total</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                             orders.map((item) => (
                                                 <tr className="border-b dark:border-neutral-500 cursor-pointer" key={item.id} onClick={() => route(`/orders/${item.id}`)}>
                                                     <td className="whitespace-nowrap px-6 py-4 font-medium">{item.id}</td>
                                                     <td className="whitespace-nowrap px-6 py-4 font-medium">
                                                         {item.createAt ? new Date(item.createAt.seconds * 1000 + item.createAt.nanoseconds / 1000000).toLocaleDateString() : ''}
                                                     </td>
                                                     <td className="whitespace-nowrap px-6 py-4">{item.email}</td>
                                                     <td className="whitespace-nowrap px-6 py-4">{item.name}</td>
                                                     <td className="whitespace-nowrap px-6 py-4">{item.phone}</td>
                                                     <td className="whitespace-nowrap px-6 py-4">{item.address}</td>
                                                     <td className="whitespace-nowrap px-6 py-4">{item.total}</td>
                                                 </tr>
                                             ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </div>
        </div>
    )
}

export default Order;
