import {getDownloadURL, ref, uploadBytes, deleteObject} from 'firebase/storage';
import {storage} from '../../firebase/firebase.config';

export const uploadImage = async ({path, blob}) => {
    try {
        const storageRef = await ref(storage, `${path}/` + blob.name);
        const image = await uploadBytes(storageRef, blob)
        const url = await getDownloadURL(image.ref);
        return { success: false, message: url };
    } catch (error) {
        return { success: false, error: error.message };
    }
}

export const deleteImage = async (image) => {
    const httpsReference = ref(storage, image);
    try {
        const value = await deleteObject(httpsReference);
        return {success: true, value}
    } catch (error) {
        console.log(error)
        return { success: false, error: error.message }
    }

}
