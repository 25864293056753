import { useSelector } from 'react-redux';
import {selectCartPriceTotal} from '../../redux/cart/cart.slice';
import {useNavigate} from 'react-router-dom';

function CartBill() {
    const total = useSelector(selectCartPriceTotal);
    const routes = useNavigate()

    return(
        <div className='h-fit p-[12px] md:p-[24px] rounded-[8px] bg-light w-full md:w-[25%] gap-[16px] shadow'>
            <span className='font-light'>Price Details</span>
            <div className='mt-[16px] font-light text-typography-700'>
                <div className='flex justify-between'>
                    <span>Subtotal</span>
                    <span>Nu {total}</span>
                </div>
                <div className='flex justify-between mt-[16px] '>
                    <span>Shipping Charges</span>
                    <span>Nu 0</span>
                </div>
                <div className='flex justify-between mt-[16px] '>
                    <span>Tax</span>
                    <span>Nu 0</span>
                </div>
                <div className='flex justify-between mt-[16px] '>
                    <span className='text-typography-900 font-bold'>Total Amount</span>
                    <span className='text-typography-900 font-bold'>Nu {total}</span>
                </div>
            </div>
            <button disabled={!total} onClick={() => routes('/checkout')}
                    className={`w-full mt-[24px] py-[12px] rounded-[8px] ${ !total ? 'bg-secondary text-dark' : 'text-light bg-primary text-white'}`}>
                PROCESS TO BUY
            </button>
        </div>
    );
}

export default CartBill;
