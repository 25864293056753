import {FaEnvelope, FaFacebook, FaInstagram, FaMapMarkerAlt, FaPhoneAlt, FaTiktok, FaTwitter} from 'react-icons/fa';
import {FOOTER_LINKS} from './constant/footer.constant';
import {Link, useNavigate} from 'react-router-dom';
import {text} from '../../../pages/about-us/about-contant';

function Footer() {
    const route = useNavigate();
    return(
        <div className='bg-secondary py-[40px] px-[24px] xl:px-[122px]'>
            <div className='flex flex-wrap md:flex-nowrap mb-[46px] gap-[14px]'>
                <div className='w-full lg:w-[25%]'>
                    <span className='text-primary font-[700] cursor-pointer' onClick={()=> route('/about-us')}>ABOUT US</span>
                    <div className='my-[12px]'>
						<span className='text-[12px] text-typography-700'>
						{`${text.slice(0, 150)}....`}
						</span>
                    </div>
                    <div className='flex gap-[12px] text-primary'>
                        <FaFacebook className='cursor-pointer' onClick={() => window.open('https://www.facebook.com/profile.php?id=100093509656417&mibextid=ZbWKwL')} />
                        <FaInstagram className='cursor-pointer' onClick={() => window.open('https://www.instagram.com/sattva_essential?igsh=MWR1cWExeGsyYWI4Mg%3D%3D')} />
                        <FaTwitter className='cursor-pointer' onClick={() => window.open('https://www.instagram.com/sattva_essential?igsh=MWR1cWExeGsyYWI4Mg%3D%3D')} />
                        <FaTiktok className='cursor-pointer' onClick={() => window.open('https://www.tiktok.com/@sattva_essentials?_t=8jroFjThZK5&_r=1')}/>
                    </div>
                </div>
                <div className='w-full lg:w-[25%]'>
                    <span className='text-primary font-[700]'>QUICK LINKS</span>
                    <div className='flex flex-col my-[12px] gap-[14px]'>
                        {
                            FOOTER_LINKS.map(({ id, label, link }) => (
                                <Link to={link} className='text-[12px] text-typography-700 no-underline' key={id}>
                                    {label}
                                </Link>
                            ))
                        }
                    </div>
                </div>
                <div className='w-full lg:w-[25%]'>
                    <span className='text-primary font-[700]'>HELP CENTER</span>
                    <div className='flex flex-col my-[12px] text-typography-700 text-[12px] gap-2'>
                        {
                            FOOTER_LINKS.map(({ id, label, link }) => (
                                <Link to={link} className='text-[12px] text-typography-700 no-underline' key={id}>
                                    {label}
                                </Link>
                            ))
                        }
                    </div>
                </div>
                <div className='w-full lg:w-[25%]'>
                    <span className='text-primary font-[700]'>CONTACT</span>
                    <div className='flex my-[12px] text-typography-700 text-[12px] gap-2 items-center'>
                        <FaMapMarkerAlt />
                        <span>Bonday, Paro, Bhutan </span>
                    </div>
                    <div className='flex my-[12px] text-typography-700 text-[12px] gap-2 items-center'>
                        <FaPhoneAlt />
                        <span>+975-17837770</span>
                    </div>
                    <div className='flex my-[12px] text-typography-700 text-[12px] gap-2 items-center'>
                        <FaEnvelope />
                        <span>elixirs.bht@gmail.com</span>
                    </div>
                </div>
            </div>
            <hr className='border-dark' />
            <div className='flex mt-[12px] text-typography-700'>
                <div className='flex w-[50%]'>
					<span className='font-light text-[10px]'>©
                        {new Date().getFullYear()} Bhutan Bath Essentials
						. All rights reserved.</span>
                </div>
                <div className='flex flex-wrap justify-end w-[50%]'>
                    <span className='font-light text-[10px]'>Design and Developed by</span>
                    <span className='font-bold text-[10px] ml-[4px] cursor-pointer text-primary' onClick={() => window.open('https://jigmeloday.com')}>
                        Jigme Lodey
                    </span>
                </div>
            </div>
        </div>
    )
}

export default Footer;
