function TextArea(props) {
    return(
        <div className='w-full'>
            <textarea name={props.name}
                      placeholder={props.placeholder}
                      value={props.value}
                      onChange={props.handleChange}
                      onBlur={props.onBlur}
                      className={`h-[140px] py-[8px] px-[12px] w-full ${props.error ? 'border-error': 'border-dark focus:border-primary'} rounded-[8px] bg-light border-2 outline-none focus:ring-0 `}/>
            {
                props.error ? <span className='text-[12px] ml-[4px] text-error'>{props.error}</span> : null
            }
        </div>
    );
}

export default TextArea;
