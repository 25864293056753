import { FaRegCheckCircle } from 'react-icons/fa';
import {BENEFIT_LIST} from './constant/benefit-section.constant';

function BenefitSection() {
    return(
        <div className='flex flex-wrap xl:mt-[48px] xl:justify-between '>
            {
                BENEFIT_LIST.map(({ section , id }) => (
                    <div key={id} className='p-[24px] md:w-[50%] xl:w-auto'>
                        {
                            section.map((item) => (
                                <div key={item} className='flex items-center py-[16px] gap-[8px]'>
                                    <FaRegCheckCircle className='text-primary' />
                                    <span className='text-typography-700 text-[14px]' >{item}</span>
                                </div>
                            ))
                        }
                    </div>
                ))
            }
        </div>
    );
}

export default BenefitSection;
