import { useDispatch, useSelector } from 'react-redux';
import { FaMinus, FaPlus } from 'react-icons/fa';
import {addToCart, removeAllItem, removeFromCart, selectCart, selectCartPriceTotal} from '../../redux/cart/cart.slice';
import noData from '../../assets/image/no-data-pana.png'

function CartPricing() {
    const cartItem = useSelector(selectCart);
    const total = useSelector(selectCartPriceTotal);
    const dispatch = useDispatch();

    return(
        <div className='border border-secondary p-[12px] md:p-[24px] rounded-[8px] w-full md:w-[75%]'>
            {
                !cartItem.length ?
                    <div className='flex w-full justify-center items-center'>
                        <div className=' w-[250px] h-[250px]'>
                            <img src={noData} alt='no data' className='opacity-60'/>
                        </div>
                    </div>:
                cartItem.map((item) => (
                    <div className='flex flex-wrap md:flex-nowrap py-[24px] border-secondary border-b' key={item?.id}>
                        <div className='md:h-[100px] md:w-[120px] w-full h-[240px]'>
                            <img src={item?.image} alt='product' className='h-full w-full rounded-[4px] object-contain'/>
                        </div>
                        <div className='flex w-full flex-col md:flex-row gap-[24px] md:gap-0 md:mt-0 mt-[12px]'>
                            <div className='flex flex-col justify-center md:ml-[34px] gap-[12px] md:w-[50%] w-full'>
                                <p className='font-light'>{item?.title}</p>
                                <p className='font-light text-[12px]'>Size: { item?.size || '_' }</p>
                                <p className='font-light text-[12px]'>
                                    {item?.description.slice(0,100)}
                                </p>
                            </div>
                            <div className='flex gap-[12px] justify-center items-center md:ml-[34px] md:w-[25%] w-full'>
                                <button onClick={() =>
                                    dispatch(removeFromCart(item))} disabled={item?.quantity === 1}
                                        className='bg-transparent border-primary p-[4px] rounded-[6px] border text-primary'>
                                    <FaMinus />
                                </button>
                                <span>{item?.quantity}</span>
                                <button onClick={() => dispatch(addToCart(item))}
                                        className='bg-transparent border-primary p-[4px] rounded-[6px] border text-primary'>
                                    <FaPlus />
                                </button>
                            </div>
                            <div className='flex flex-col md:ml-[34px] md:items-end md:w-[25%] w-full items-center'>
                                <span>Nu {item?.price}</span>
                                <div className='h-full flex items-end'>
									<span onClick={() => dispatch(removeAllItem(item))} className='cursor-pointer
										 bg-transparent text-primary font-light'> REMOVE </span>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
            <div className='mt-[24px] flex w-full bg-secondary py-[12px] px-[24px] justify-between rounded-[8px]'>
				<span className='font-bold'>
						Subtotal
				</span>
                <span className='font-bold'>
						Nu {total}
				</span>
            </div>
        </div>
    );
}

export default CartPricing;
