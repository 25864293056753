import { Formik } from 'formik';
import Input from '../../components/shared/input/input';
import {LOGIN_SCHEMA} from './mics/login.validation';
import {useDispatch, useSelector} from 'react-redux';
import {selectIsLoading, userLogin} from '../../redux/auth/auth.slice';

function Login() {
    const dispatch = useDispatch();
    const loading = useSelector(selectIsLoading)
    const handleLogin = (value) => {
        dispatch(userLogin(value))
    }

    return(
        <div className='flex flex-col justify-center items-center h-screen px-[24px]'>
           <div className='shadow py-[32px] px-[24px] rounded-[8px] w-full md:w-[50%] xl:w-[24%]'>
               <div className='flex flex-col items-center justify-center w-full mb-[24px]'>
                   <span className='text-primary font-bold text-[32px]'>Login</span>
               </div>
               <Formik
                   validationSchema={LOGIN_SCHEMA}
                   initialValues={{ email: '', password: '' }}
                   onSubmit={(values) => handleLogin(values)}>
                   {({handleSubmit, handleChange, values, errors, touched, handleBlur }) => (
                       <div className='flex flex-col gap-[8px]'>
                           <Input name='email' handleChange={handleChange} value={values.email} onBlur={handleBlur} placeholder='Email' error={touched.email && !!errors.email && errors.email} />
                           <Input name='password' handleChange={handleChange} value={values.password} onBlur={handleBlur} placeholder='Password' type='password' error={touched.password && !!errors.password && errors.password} />
                           <button disabled={loading} type='button' onClick={handleSubmit}
                                   className={`w-full py-[8px] rounded-[8px] ${loading ? 'bg-disabled' : 'bg-primary text-light'}`}>Login</button>
                       </div>
                   )}
               </Formik>
           </div>
            <div className='mt-[20px]'>
                <span className='text-error text-[12px]'>* You can purchase products without logging in or creating an account for now.</span>
            </div>
        </div>
    )
}

export default Login
