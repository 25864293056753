import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {getBlogData, selectBlogs, selectIsLoading} from '../../redux/blog/blog.slice';
import {useEffect} from 'react';
import ProductLoader from '../../components/shared/loader/product.loader';

function Blogs() {
    const route = useNavigate();
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsLoading);
    const blogs = useSelector(selectBlogs)
    useEffect(() => {
        if (!blogs?.length) {
            dispatch(getBlogData())
        }
    }, [])
    return(
        <div className='flex flex flex-wrap px-[12px] lg:px-[122px] my-[38px] '>
            { isLoading ? <ProductLoader /> :
                blogs?.map((item) => (
                    <div key={item?.id} className='w-full md:w-1/2 lg:w-1/3 xl:w-1/4 p-[8px] '>
                        <div className='bg-light rounded-[8px] shadow cursor-pointer' onClick={() => route(`/blogs/${item?.id}`)}>
                            <div className='relative h-[250px] w-full'>
                                <img src={item.image} alt='blog'
                                       className='object-cover h-full w-full rounded-t-[8px]' />
                                <div className='bg-overlay px-[24px] text-light text-[20px]
								 py-[16px] z-10 absolute w-full bottom-0 border-primary border-l-4'>
                                    {item?.title}
                                </div>
                            </div>
                            <div className='py-[24px] px-[16px]'>
									<span className=' text-[12px]'>
									{`${item?.subtitle?.slice(0, 100)}${item.subtitle.length > 100 ? '...': ''}`}
									</span>
                                <div className='flex mt-[10px] justify-between items-end'>
                                    <span className='font-light text-[12px]'>
                                        {item.createAt ? new Date(item?.createAt?.seconds * 1000 + item?.createAt?.nanoseconds / 1000000).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: '2-digit' }) : ''}

                                    </span>
                                    <span className='font-light text-[12px]'>{item?.read} min read</span>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
            {/*<div className='flex justify-center w-full mt-[24px]'>*/}
            {/*    <button className='bg-primary text-white px-[12px] py-[8px] rounded-[8px] text-light'>LOAD MORE</button>*/}
            {/*</div>*/}
        </div>
    )
}

export default Blogs;
