import logo from '../../../assets/image/logo.png';
import {useEffect, useState} from 'react';
import {AUTHENTICATED_HEADER_LINKS, HEADER_LINKS} from './constant/header.constant';
import {FaBars, FaTimes} from 'react-icons/fa';
import CartIcon from '../cart-icon/cart-icon';
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import {selectUser, userLogout} from '../../../redux/auth/auth.slice';

export default function Header() {
    const [scroll, setScroll] = useState();
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch()
    const user = useSelector(selectUser)
    const route = useNavigate();
    const params = useLocation().pathname;
    const handleScroll = () => {
        setScroll(window.scrollY)
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={`sticky top-0 w-full px-[24px] xl:px-[122px] py-[12px] z-50 transition duration-300
        bg-light backdrop-blur-2xl ${open? 'opacity-100': 'opacity-[0.9]'} inset-0 ${scroll>10 ? 'shadow' : 'none' }`}>
           <div className='flex justify-between'>
               <div className='border-primary border h-[32px] cursor-pointer w-[32px] md:h-[50px] md:w-[50px] rounded-full' onClick={() => route('/')} >
                   <img src={logo} alt='logo' className='object-cover h-full w-full' />
               </div>
               <div className='hidden md:flex items-center'>
                   {(user?.user ? AUTHENTICATED_HEADER_LINKS : HEADER_LINKS ).map(({ label , link}) => (
                           <div className='px-[24px]' key={link}>
                               <span className={`cursor-pointer transition duration-300 ${params === link ? 'text-primary' : ''} hover:text-dark`} onClick={() => route(link)}>
                                   {label}
                               </span>
                           </div>
                       ))}
               </div>
               <div className='hidden md:flex  '>
                   <div className='flex items-center'>
                       <button onClick={() => route('/cart')}><CartIcon  /></button>
                       {
                           !user?.user?.uid ? <button className='ml-[20px] border-primary border-2 px-[8px] py-[4px] rounded-[8px] text-primary' onClick={() => route('/login')}>LOGIN</button> :
                               <button className='ml-[20px] border-primary border-2 px-[8px] py-[4px] rounded-[8px] text-primary' onClick={() => dispatch(userLogout())}>LOG OUT</button>
                       }
                   </div>
               </div>
               <div className='flex md:hidden items-center text-primary text-[20px]'>
                   <button onClick={() => route('/cart')}><CartIcon  /></button>
                   <FaBars onClick={() => setOpen(!open)} />
               </div>
           </div>
            <div className={`fixed top-0 right-0 py-[24px] bg-[white] opacity-100 h-screen transition duration-300 w-full ${open? 'block' : 'hidden'}`}>
                <div className='flex px-[12px] justify-between items-center'>
                    <div className='border-primary border cursor-pointer h-[50px] w-[50px] rounded-full' onClick={() => {
                        route('/');
                        setOpen(!open)
                    }} >
                        <img src={logo} alt='logo' className='object-cover h-full w-full' />
                    </div>
                    <div className='px-[12px] text-dark'>
                        <FaTimes className='text-[30px]' onClick={() => setOpen(!open)} />
                    </div>
                </div>
                <div className='mt-[32px]'>
                    {(user?.user ? AUTHENTICATED_HEADER_LINKS : HEADER_LINKS ).map(({ label , link}) => (
                        <div className='px-[24px] mt-[16px]' key={link}>
                        <span className={`cursor-pointer font-bold  transition duration-300 ${params === link ? 'text-dark' : ''}`} onClick={() => {
                            route(link);
                            setOpen(!open)
                        }}>
                            {label}
                        </span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
