import { Formik } from 'formik';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Input from '../../components/shared/input/input';
import {useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {createBlog, selectIsLoading} from '../../redux/blog/blog.slice';
import {BLOG_SCHEMA} from './misc/blog.validation';
import {toast} from 'react-toastify';

function BlogCreation() {
    const isLoading = useSelector(selectIsLoading);
    const fileInputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [preview, setPreview] = useState('');
    const dispatch = useDispatch();

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        const fileUri = URL.createObjectURL(file);
        setPreview(fileUri);
    };

    const handleSubmit = (value) => {
       const data = {
           ...value,
           image: selectedFile
       }
       if (selectedFile) {
           dispatch(createBlog(data))
       } else {
           toast('Image is missing', {
               type: 'error'
           })
       }
    }
    return(
        <div className='flex flex-col items-center px-[122px] my-[42px]'>
            <div className='shadow w-[40%] rounded-[4px]'>
                <div className='px-[16px] pt-[20px]'>
                    <span className='font-bold'>Blog Creation</span>
                </div>
                <Formik validationSchema={BLOG_SCHEMA}
                        initialValues={{ title: '', blog: '', subtitle: '', read: '' }}
                        onSubmit={(values) => handleSubmit(values)}>
                    {({ handleSubmit, values, handleChange, setFieldValue, errors, touched, handleBlur }) => (
                        <div className='flex flex-col px-[20px] py-[24px] gap-[12px]'>
                            <Input name='title' value={values.title} handleChange={handleChange} placeholder='Title' error={touched.title && !!errors.title && errors.title} onBlur={handleBlur} />
                            <Input name='subtitle' value={values.subtitle} handleChange={handleChange} placeholder='Sub Title' error={touched.subtitle && !!errors.subtitle && errors.subtitle} onBlur={handleBlur} />
                            <Input name='read' value={values.read} handleChange={handleChange} placeholder='Reading Time' error={touched.read && !!errors.read && errors.read} onBlur={handleBlur} />
                            <div className='relative pb-[24px]'>
                                <ReactQuill
                                    onChange={(content) => setFieldValue('blog', content)}
                                    value={values.blog}
                                    className='p-0 my-[24px] h-[250px] '
                                    placeholder='Write here'
                                />
                            </div>
                            {
                                <p className='text-error'>{touched.blog && !!errors.blog && errors.blog}</p>
                            }
                            <div className='flex flex-col justify-end items-center border-primary mt-[12px]
					 			border-2 w-full h-[340px] mb-[24px] rounded-[8px] py-[12px] px-[8px]'>
                                <div className='w-[200px] h-[240px] my-[8px]'>
                                    {
                                        preview && 	<img src={preview} alt='preview'
                                                           className='object-cover h-full w-full'/>
                                    }
                                </div>
                                <input className='hidden' ref={fileInputRef} type='file' onChange={handleFileChange}/>
                                <button className='border-primary px-[12px] py-[8px] rounded-[8px]
								border-2 bg-transparent text-primary' onClick={() => {handleButtonClick()}}>Upload Image</button>
                            </div>
                            <button disabled={isLoading} className={`w-full px-[12px] py-[8px] rounded-[8px] ${isLoading ? 'bg-disabled' : 'bg-primary text-white text-light'}`}
                                    onClick={() => handleSubmit()}>Upload</button>
                        </div>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default BlogCreation;
