import React from 'react'
import { Route, Routes, Navigate} from 'react-router-dom';
import HomePage from '../pages/home-page/home-page';
import Products from '../pages/products/products';
import ProductDetails from '../pages/products/product-details';
import AboutUs from '../pages/about-us/about-us';
import Blogs from '../pages/blogs/blogs';
import BlogDetails from '../pages/blogs/blog-details';
import ProductCreation from '../pages/product-creation/product-creation';
import BlogCreation from '../pages/blogs/blog-creation';
import Cart from '../pages/cart/cart';
import Login from '../pages/auth/login';
import {useSelector} from 'react-redux';
import {selectUser} from '../redux/auth/auth.slice';
import Header from '../components/shared/header/header';
import Footer from '../components/shared/footer/footer';
import Order from '../pages/order/order';
import Checkout from '../pages/checkout/checkout';
import OrderDetail from '../pages/order-detail/order-detail';
import Contact from '../pages/contact/contact';

export const AppRouter = () => {
    const user = useSelector(selectUser)
    const UNAUTHENTICATED = [
        {
            path: '/',
            component: <HomePage />
        },
        {
            path: '/login',
            component: <Login />
        },
        {
            path: '/shop',
            component:<Products />
        },
        {
            path: `/shop/:id`,
            component: <ProductDetails />
        },
        {
            path: '/blogs',
            component: <Blogs />
        },
        {
            path: '/about-us',
            component: <AboutUs />
        },
        {
            path: '/cart',
            component: <Cart />
        },
        {
            path: '/blogs/:id',
            component: <BlogDetails />
        },
        {
            path: `/checkout`,
            component: <Checkout />
        },
    ]
    const AUTHENTICATED = [
        {
            path: '/',
            component: <HomePage />
        },
        {
            path: '/about-us',
            component: <AboutUs />
        },
        {
            path: '/cart',
            component: <Cart />
        },
        {
            path: '/blogs',
            component: <Blogs />
        },{
            path: '/blogs/:id',
            component: <BlogDetails />
        },
        {
            path: '/write',
            component: <BlogCreation />
        },
        {
            path: '/shop',
            component:<Products />
        },
        {
          path: '/create',
          component: <ProductCreation />
        },
        {
            path: `/shop/:id`,
            component: <ProductDetails />
        },
        {
            path: `/edit/:id`,
            component: <ProductCreation />
        },
        {
            path: `/orders`,
            component: <Order />
        },
        {
            path: `/checkout`,
            component: <Checkout />
        },
        {
            path: `/orders/:id`,
            component: <OrderDetail />
        },
        {
            path: `/contact`,
            component: <Contact />
        },

    ]

    return(
        <div className='flex flex-col min-h-screen'>
            <Header />
            <div className='flex-1'>
                <Routes>
                    {
                        ( user?.user ? AUTHENTICATED : UNAUTHENTICATED ).map( ( {path, component } ) =>
                            <Route key={path} path={ path } element={ component }/> )
                    }
                    {user?.user && <Route path="/login" element={<Navigate to="/" replace />} />}
                    <Route path="*" element={<Navigate to="/about-us" replace />} />
                </Routes>
            </div>
            <Footer/>
        </div>
    )
}
