function Input(props) {
    return(
        <div className='w-full my-[4px]'>
            <input
                className={`bg-light w-full border-2 ${props.error ? 'border-error' : 'border-dark focus:border-primary'} outline-none focus:ring-0  rounded-[8px] py-[8px] px-[8px]`}
                placeholder={props?.placeholder}
                value={props.value}
                name={props.name}
                type={props.type || 'text'}
                onBlur={props.onBlur}
                onChange={props.handleChange}
            />
            {
                props.error ? <span className='text-[12px] ml-[4px] text-error'>{props.error}</span> : null
            }
        </div>
    )
}

export default Input;
