export const FOOTER_LINKS = [
    {
        id: 1,
        label: 'Product',
        link: '/shop'
    },
    {
        id: 2,
        label: 'Contact',
        link: '/'
    },
    {
        id: 3,
        label: 'Blog',
        link: '/blogs'
    },
    {
        id: 4,
        label: 'About us',
        link: '/about-us'
    },
];
