import {useNavigate, useParams} from 'react-router-dom';
import {DeleteOrderAPI, FetchOrderDetailAPI} from '../../services/order/order.service';
import {useEffect, useState} from 'react';
import {toast} from 'react-toastify';

function OrderDetail() {
    const id = useParams()['id'];
    const [orders, setOrder] = useState(undefined);
    const route = useNavigate();
    useEffect(() => {
        FetchOrderDetailAPI(id).then((res) => {
            setOrder(res);
        });
    }, []);

    const handleDelete = () => {
        DeleteOrderAPI(id).then((res) => {
            if (res.success) {
                route('/orders')
                toast('Order deleted', {
                    type: 'success'
                })
            }
            if (!res.success) {
                toast('Failed delete ', {
                    type: 'error'
                })
            }
        })
    }
    return (
        <div className="flex flex-col md:flex-row px-[24px] xl:px-[122px] my-[40px] gap-[12px] lg:gap-[38px]">
            <div className="border border-secondary p-[12px] md:p-[24px]  rounded-[8px] w-full md:w-full">
                <div className="flex justify-between">
                    <span className="font-light text-typography-700"> Order Id: {orders?.id}</span>
                    <button className="px-[20px] bg-primary py-[8px] rounded-[8px] text-light" onClick={() => handleDelete()}>Delete</button>
                </div>
                <div className="flex justify-between mt-[24px]">
                    <div className="flex flex-col">
                        <span className="text-typography-700">Order Date</span>
                        <p className="text-typography-800 mt-[8px] text-[14px]">
                            {orders?.createAt ? new Date(orders?.createAt?.seconds * 1000 + orders?.createAt?.nanoseconds / 1000000).toLocaleDateString() : ''}
                        </p>
                    </div>
                    <div className="flex flex-col">
                       <span className="text-typography-700">
                           Placed by
                       </span>
                        <div className="flex flex-col gap-[12px] mt-[8px] text-[14px]">
                            <p className="text-typography-800">{orders?.name}</p>
                            <p className="text-typography-800">{orders?.email}</p>
                            <p className="text-typography-800">{orders?.phone}</p>
                        </div>
                    </div>
                    <div className="flex flex-col">
                       <span className="text-typography-700">
                           Shipping Address
                       </span>
                        <p className="mt-[8px] text-[14px] text-typography-800">
                            {orders?.address}
                        </p>
                    </div>
                </div>
                <div className="flex flex-wrap md:flex-nowrap py-[24px] border-secondary bg-secondary mt-[24px]">
                    <div className="flex justify-center w-full md:w-[120px]  ">
                        Items
                    </div>
                    <div className="flex w-full flex-col md:flex-row gap-[24px] md:gap-0 md:mt-0 mt-[12px]">
                        <div className="flex flex-col justify-center md:ml-[34px] gap-[12px] md:w-[25%] w-full">
                            Description
                        </div>
                        <div className="flex gap-[12px] justify-center items-center md:ml-[34px] md:w-[20%] w-full">
                            quantity
                        </div>
                        <div className="flex flex-col md:ml-[34px] md:items-end md:w-[20%] w-full items-center">
                            <span>Unit Price</span>
                        </div>
                        <div className="flex flex-col md:ml-[30px] md:items-end md:w-[20%] w-full items-center">
                            <span>Total</span>
                        </div>
                    </div>
                </div>
                <div className="border-b border-secondary">
                    {
                        orders?.products?.map((item) => (
                            <div className="flex flex-wrap md:flex-nowrap py-[24px] " key={item?.id}>
                                <div className="md:h-[100px] md:w-[120px] w-full h-[240px]">
                                    <img src={item?.image} alt="product"
                                         className="h-full w-full rounded-[4px] object-contain"/>
                                </div>
                                <div className="flex w-full flex-col md:flex-row gap-[24px] md:gap-0 md:mt-0 mt-[12px]">
                                    <div
                                        className="flex flex-col justify-center md:ml-[34px] gap-[12px] md:w-[25%] w-full">
                                        <p className="font-light">{item?.title}</p>
                                        <p className="font-light text-[12px]">Size: {item?.size || '_'}</p>
                                        <p className="font-light text-[12px]">
                                            {item?.description?.slice(0, 100)}
                                        </p>
                                    </div>
                                    <div
                                        className="flex gap-[12px] justify-center items-center md:ml-[34px] md:w-[20%] w-full">
                                        <span>{item?.quantity}</span>
                                    </div>
                                    <div
                                        className="flex flex-col md:ml-[34px] md:items-end md:w-[20%] w-full items-center">
                                        <span>Nu {item?.price}</span>
                                    </div>
                                    <div
                                        className="flex flex-col md:ml-[34px] md:items-end md:w-[20%] w-full items-center">
                                        <span>Nu {item?.price * item?.quantity}</span>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className="flex justify-end w-full pt-[16px] text-typography-700">
                    <div className="flex flex-col gap-[12px] md:ml-[34px] md:items-end md:w-[15%] w-full items-center">
                        <p>Sub Total: </p>
                        <p>Tax: </p>
                        <p>Shipping Charge: </p>
                        <p className="text-typography-800">TOTAL AMOUNT: </p>
                    </div>
                    <div
                        className="text-typography-800 flex flex-col gap-[12px] md:ml-[34px] md:items-end md:w-[15%] w-full items-center">
                        <p>NU. {orders?.total} /-</p>
                        <p>0 </p>
                        <p>0 </p>
                        <p>NU. {orders?.total} /- </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OrderDetail;
