import { signInWithEmailAndPassword, getAuth, signOut } from 'firebase/auth';
import {toast} from 'react-toastify';

const auth = getAuth();
export const LoginAPI = async (userData) => {
    try {
        const userCredential = await signInWithEmailAndPassword(auth, userData.email, userData.password);
        const user = userCredential.user;
        if (user) {
            return { success: true, user };
        }
        toast('Email or Password must be wrong', {
            type: 'error'
        })
    } catch (error) {
        toast('Sorry something went wrong', {
            type: 'error'
        })
        return { success: false, error: error.message };
    }
}

export const LogoutAPI = async () => {
    try {
        await signOut(auth);
        return {success: true};
    } catch (error) {
        console.error("Error logging in: ", error);
        return { success: false, error: error.message };
    }
}
