import {collection, doc, getDoc, getDocs, addDoc, query, orderBy, deleteDoc, limit, updateDoc} from "firebase/firestore";
import { db } from '../../firebase/firebase.config';
import {deleteImage, uploadImage} from '../shared/shared.api';


export const FetchProductAPI = async (page) => {
    const productsRef = collection(db, 'products');
    try {
        const q = query(productsRef, orderBy('createAt', 'desc') );
        const querySnapshot = await getDocs(q);
        const products = [];
        querySnapshot.forEach((doc) => {
            products.push({ id: doc.id, ...doc.data() });
        });
        return products;
    } catch (error) {
        return error
    }
}

export const FetchSingleProduct = async (productId) => {
    const productDoc = doc(db, 'products', productId);
    const productSnapshot = await getDoc(productDoc);
    if (productSnapshot.exists()) {
        return { id: productSnapshot.id, ...productSnapshot.data() };
    }
    return null;
}

export const FetchProductFeaturedAPI = async () => {
    // const productsRef = collection(db, 'featuredProducts');
    // try {
    //     const querySnapshot = await getDocs(productsRef);
    //     const featuredProductIds = [];
    //     querySnapshot.forEach((doc) => {
    //         featuredProductIds.push({...doc.data()} );
    //     });
    //
    //     return await Promise.all(
    //         featuredProductIds.map(async (item) => await FetchSingleProduct(item.id))
    //     );
    // } catch (error) {
    //     return error
    // }
    const productsRef = collection(db, 'products');
    try {
        const q = query(productsRef, orderBy('createAt', 'desc'), limit(4) );
        const querySnapshot = await getDocs(q);
        const products = [];
        querySnapshot.forEach((doc) => {
            products.push({ id: doc.id, ...doc.data() });
        });
        return products;
    } catch (error) {
        return error
    }
}

export const CreateProductAPI = async (productData) => {
    try {
        const upload = {
            blob: productData.image,
            path: '/products'
        }
        const image = await uploadImage(upload);
        if (image.message) {
            await addDoc(collection(db, 'products'), {
                ...productData,
                createAt: new Date(),
                image: image.message
            });
        }
        return { success: true };
    } catch (error) {
        return { success: false, error: error.message };
    }
}

export const DeleteProductAPI = async (product) => {
    const productRef = doc(db, 'products', product.id);
    try {
        if (product.image) {
            const imageDelete = await deleteImage(product.image)
            if (imageDelete.success) {
                await deleteDoc(productRef);
                const data = await FetchProductAPI();
                return { success: true, data };
            }
        } else {
            const productRef = doc(db, 'products', product.id);
            await deleteDoc(productRef);
            return { success: true };
        }
        return {success: false}
    } catch (error) {
        console.error("Error deleting product: ", error);
        return { success: false, error: error.message };
    }
}

export const UpdateProductAPI = async (product) => {
    const productRef = doc(db, 'products', product.id);
    try {
        const val = await updateDoc(productRef, {
                title: product.title,
                price: product.price,
                size: product.size,
                brand: product.brand,
                description: product.description

        });
        console.log(val)
        return { success: true };
    } catch (error) {
        console.error("Error updating product: ", error);
        return { success: false, error: error.message };
    }
}
