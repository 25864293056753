import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import {createBlogAPi, GetBlogAPi} from '../../services/blog-service/blog.api';
import {LoginAPI, LogoutAPI} from '../../services/auth/auth.service';

export const AUTH_KEY = 'auth';

export const AUTH_INITIAL_STATE = {
    user: undefined,
    isLoading: false
};

export const userLogin = createAsyncThunk(
    'auth/userLogin',
    async ( payload, _, thunkAPI ) => {
        const response = await LoginAPI(payload);
        if ( response ) {
            return response;
        }
        if ( !response.length ) {
            return thunkAPI.rejectWithValue('error' );
        }
    }
);

export const userLogout = createAsyncThunk(
    'auth/userLogout',
    async ( _, thunkAPI ) => {
        const response = await LogoutAPI();
        if ( response ) {
            return response;
        }
        if ( !response.length ) {
            return thunkAPI.rejectWithValue('error' );
        }
    }
);

export const authSlice = createSlice({
    name: AUTH_KEY,
    initialState: AUTH_INITIAL_STATE,
    reducers: {},
    extraReducers: (builder) =>{
        builder
            .addCase(userLogin.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(userLogin.fulfilled, ( state, action ) => {
                state.user = action.payload;
                state.isLoading = false;
            } )
            .addCase(userLogin.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(userLogout.pending, (state) => {
                state.isLoading = true
            })
            .addCase(userLogout.fulfilled, (state, action) => {
                state.user = undefined;
                state.isLoading = false
            })
            .addCase(userLogout.rejected, (state) => {
                state.isLoading = false
            })
        ;
    }
});

export const authReducer = authSlice.reducer;

export const getBlog = (rootState) => rootState[AUTH_KEY];
export const selectUser = createSelector(getBlog, (state) => state.user);
export const selectIsLoading = createSelector(getBlog, (state) => state.isLoading);
