import {useDispatch, useSelector} from 'react-redux';
import {getProductData, selectIsLoading, selectProducts} from '../../redux/product/product.slice';
import {useEffect} from 'react';
import ProductCard from '../../components/shared/product-card/product-card';
import ProductLoader from '../../components/shared/loader/product.loader';

function Products() {
    const dispatch = useDispatch()
    const products = useSelector(selectProducts);
    const isLoading =useSelector(selectIsLoading);
    useEffect(() => {
        dispatch(getProductData(1));
    }, []);

    return(
        <div className='px-[24px] xl:px-[122px] my-[56px]'>
            {
                <div className='flex flex-wrap'>

                    { isLoading ?  <ProductLoader /> :
                        products?.map((item) => (
                            <div className='my-4 px-2 w-full md:w-1/2 lg:w-1/3 xl:w-1/4' key={item?.id}>
                                <ProductCard item={item} />
                            </div>
                        ))
                    }
                    {/*<div className='flex justify-center w-full mt-[24px]'>*/}
                    {/*    <button className='bg-primary text-white px-[12px] py-[8px] rounded-[8px] text-light'>LOAD MORE</button>*/}
                    {/*</div>*/}
                </div>
            }
        </div>
    )
}

export default Products;
