import {FaCartPlus} from 'react-icons/fa';
import {useDispatch, useSelector} from 'react-redux';
import {addToCart} from '../../../redux/cart/cart.slice';
import {DUMMY_IMAGE} from '../../../shared/constant/shared.constant';
import {deleteProduct, selectIsLoading} from '../../../redux/product/product.slice';
import {useNavigate} from 'react-router-dom';
import {selectUser} from '../../../redux/auth/auth.slice';

function ProductCard({item}) {
    const loading = useSelector(selectIsLoading);
    const user = useSelector(selectUser);
    const dispatch = useDispatch();
    const router = useNavigate()
    return(
        <div className='relative group transition duration-700 ease-in-out'>
            <div className='flex flex-col px-[10px]
			 w-full shadow-sm  pt-[4px] rounded-[8px]'>
                <div className='h-[250px] py-[8px] overflow-hidden  rounded-[8px] cursor-pointer' onClick={() => router(`/shop/${item.id}`) }>
                    <img src={item?.image || DUMMY_IMAGE} alt='products'
                         className='object-cover h-full w-full rounded-t-[8px]' />
                </div>
                <div className='flex flex-col w-full my-[16px] justify-center items-center cursor-pointer'  onClick={() => router(`/shop/${item.id}`) }>
                    <div className='flex w-full justify-between'>
                        <span className='text-[16px] font-[500]'>{item?.title}</span>
                        <span>Nu {item?.price}</span>
                    </div>
                    <div className='w-full mt-[4px]'>
                        <p className='text-[14px] font-[400]   text-typography-700'>
                            {`${item?.description?.slice(0,100)} ${item?.description.length > 100 ? '...' : ''}`}
                        </p>
                    </div>
                </div>
                {
                  user?.user?  <div className='flex w-full justify-between my-[12px]'>
                        <button onClick={() => router(`/edit/${item.id}`)} className={`border-2 rounded-[8px] px-[8px] py-[4px] ${loading ? 'text-disabled' : 'text-primary'}`} >Edit</button>
                        <button disabled={loading} className={`border-2 rounded-[8px] ${loading ? 'text-disabled' : 'text-error'} px-[8px] py-[4px]`}
                                onClick={() => dispatch(deleteProduct(item))}>
                            Delete
                        </button>
                    </div> : null
                }
            </div>
            <button className='bg-transparent text-primary flex transition duration-700 delay-150 ease-in-out
			xl:opacity-0 group-hover:opacity-100
			justify-center items-center absolute
			 w-[36px] h-[36px] right-4 top-4
			 cursor-pointer text-primary
			 rounded-full border-2 border-primary'
                    onClick={() => dispatch(addToCart(item))}>
                <FaCartPlus />
            </button>
        </div>
    )
}

export default ProductCard;
