import {useDispatch, useSelector} from 'react-redux';
import {getBlogData, selectBlogs} from '../../../redux/blog/blog.slice';
import parse from 'html-react-parser';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

function BlogSection() {
    const dispatch = useDispatch();
    const blogs = useSelector(selectBlogs);
    const route = useNavigate();

    useEffect(() => {
       if(!blogs?.length) {
           dispatch(getBlogData());
       }
    }, [])
    return(
        <div>
            {
                blogs?.slice(0,3).map((item, index) => {
                    const slicedBlog = item?.blog?.slice(0, 500);
                    const parsedBlog = parse(slicedBlog);

                    return(
                        <div className={ `flex justify-between ${ index === 1 ? 'flex-row-reverse' : 'flex-row' }
						 ${ index >= 1 ? 'mt-[80px]' : 'mt-[0px]' }` } key={ index }>
                            <div className='flex flex-col xl:w-[44%] md:w-[50%]'>
                                <span className='text-primary md:text-[18px]'>My Blog</span>
                                <span className='text-[24px]  xl:text-[42px] font-[700]'>{ item?.title }</span>
                                <div className='font-[400] mt-[24px]'>
                                    <span>{parsedBlog}</span>
                                </div>
                                <div className='mt-[20px]'>
                                    <button className='border-2 border-primary text-primary px-[12px] py-[8px] rounded-[16px]' onClick={() => route(`blogs/${item.id}`) } >
                                        Read More
                                    </button>
                                </div>
                            </div>
                            <div className={`hidden md:flex w-[40%] xl:w-[540px] xl:h-[340px] items-end ${ index === 1 ? 'justify-start' : 'justify-end'} `}>
                                <img src={item?.image} alt='blog' className='object-cover h-full w-full' />
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
}

export default BlogSection;
