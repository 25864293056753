import * as Yup from 'yup';

export const BLOG_SCHEMA = Yup.object().shape( {
    title: Yup.string()
        .required('Title is required'),
    subtitle: Yup.string()
        .required('Sub Title is required'),
    read: Yup.string()
        .required( 'Read Time is required' ),
    blog: Yup.string()
        .required( 'Blog is required' ),
} );
