import {FaImage} from 'react-icons/fa';

function ProductLoader() {
    return(
        <div className='flex flex-col flex-wrap md:flex-row w-full'>
            {
                [1, 2, 3, 4].map((item) => (
                    <div className='my-4 px-2 w-full md:w-1/2 lg:w-1/3 xl:w-1/4 animate-pulse' key={item}>
                        <div className=' h-[300px] rounded-[8px] bg-[white]'>
                            <div className='flex justify-center items-center h-[180px] bg-skeleton rounded-t-[8px]'>
                                <FaImage className='text-disabled text-[32px]' />
                            </div>
                            <div className='mt-[16px] flex justify-between px-[12px]'>
                                <div className='w-[100px] h-[8px] bg-disabled' >

                                </div>
                                <div className='w-[100px] h-[8px] bg-disabled' >

                                </div>
                            </div>
                            <div className='mt-[16px] px-[12px]'>
                                <div className='w-full h-[8px] bg-disabled' />
                            </div>
                            <div className='mt-[16px] px-[12px]'>
                                <div className='w-full h-[8px] bg-disabled' />
                            </div>
                            <div className='mt-[16px] px-[12px]'>
                                <div className='w-full h-[8px] bg-disabled' />
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default ProductLoader;
