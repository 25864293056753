import {addToCart} from '../../redux/cart/cart.slice';

function ProductLoader() {
    return(
        <div className='flex flex-col md:flex-row animate-pulse'>
            <div className='md:h-[440px] md:w-[440px] bg-disabled bg-opacity-60 rounded '/>
            <div className='w-full mt-[24px] md:ml-[24px] lg:w-[30%]'>
                <div className='h-[8px] bg-disabled bg-opacity-60 w-[100px] rounded'/>
                <div className='flex flex-col mt-[12px] gap-[12px]'>
                    <div className='h-[8px] bg-disabled bg-opacity-60 w-[140px] rounded'/>
                    <div className='h-[8px] bg-disabled bg-opacity-60 w-[140px] rounded'/>
                    <div className='h-[8px] bg-disabled bg-opacity-60 w-[140px] rounded'/>
                </div>
                <div className='flex flex-col mt-[24px] gap-[16px]'>
                    <div className='h-[8px] bg-disabled bg-opacity-60 w-[100px] rounded'/>
                    <div className='flex flex-col gap-[12px]'>
                        <div className='h-[8px] bg-disabled bg-opacity-60 w-[200px] rounded'/>
                        <div className='h-[8px] bg-disabled bg-opacity-60 w-[200px] rounded'/>
                        <div className='h-[8px] bg-disabled bg-opacity-60 w-[200px] rounded'/>
                        <div className='h-[8px] bg-disabled bg-opacity-60 w-[200px] rounded'/>
                        <div className='h-[8px] bg-disabled bg-opacity-60 w-[200px] rounded'/>
                    </div>

                </div>
                <button className='bg-disabled text-white mt-[28px] px-[12px] py-[8px] w-[120px] h-[50px] rounded-[8px] text-light'/>
            </div>
        </div>
    )
}

export default ProductLoader;
