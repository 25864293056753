import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import persistStore from 'redux-persist/es/persistStore';
import storage from 'redux-persist/lib/storage';
import {PRODUCT_KEY, productReducer} from './product/product.slice';
import {CART_KEY, cartReducer} from './cart/cart.slice';
import {BLOG_KEY, blogReducer} from './blog/blog.slice';
import {AUTH_KEY, authReducer} from './auth/auth.slice';
import {CONTACT_KEY, contactReducer} from './contact/contact.slice';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: [ AUTH_KEY,CART_KEY ]
};

const reducers = combineReducers( {
    [PRODUCT_KEY]: productReducer,
    [CART_KEY]: cartReducer,
    [BLOG_KEY]: blogReducer,
    [CONTACT_KEY]: contactReducer,
    [AUTH_KEY]: authReducer
} );

const persistedReducer = persistReducer( persistConfig, reducers );

export const store = configureStore( {

    reducer: persistedReducer,
    middleware: ( getDefaultMiddleware ) => getDefaultMiddleware( {
        serializableCheck: false
    } ),
} );

export const persistor = persistStore( store );
