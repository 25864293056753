import {useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {GetSingleBlogAPi} from '../../services/blog-service/blog.api';
import parse from 'html-react-parser';
import BlogLoader from './blog-loader';

function BlogDetails() {
	const id = useParams()['id'];
    const [loading, setLoading] = useState(false)
	const [blog, setBlog] = useState(null);
	useEffect(() => {
        setLoading(true)
		const getBlogById = async () => {
			const data = await GetSingleBlogAPi(id);
			setBlog(data);
            setLoading(false)
		}
		getBlogById();
	}, []);

    return(
        <div className='flex md:justify-center px-[24px] lg:px-[122px] my-[48px]'>
            {
                loading ? <BlogLoader /> : (
                    <div className='w-full md:w-[75%] xl:w-[50%]'>
				<span className='font-bold md:font-extrabold text-[20px] md:text-[28px]'>
					{blog?.title}
				</span>
                        <div className='flex flex-col mt-[12px]'>
					<span className='text-typography-700'>
						{blog?.subtitle}
					</span>
                            <div className='mt-[24px] md:h-[440px] h-[220px] w-full'>
                                <img src={blog?.image} alt='blog'
                                     className='object-cover h-full w-full ' />
                            </div>
                            <div className='flex gap-[16px] md:px-[12px] mt-[4px]'>
								<span className='font-light text-[12px]'>
									{blog?.createAt ? new Date(blog?.createAt?.seconds * 1000 + blog?.createAt?.nanoseconds / 1000000).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: '2-digit' }) : ''}
								</span>
                                <span className='font-light text-[12px]'>Read {blog?.read} min</span>
                            </div>
                            <div className='mt-[24px]'>
                                {blog && parse(blog?.blog)}
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    );
}

export default BlogDetails;
