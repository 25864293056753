import {Formik} from 'formik';
import Input from '../../shared/input/input';
import TextArea from '../../shared/text-area/text-area';
import {CONTACT_SCHEMA} from './misc/contact.validation';
import {CreateContactPI} from '../../../services/contact/contact.service';
import {useState} from 'react';
import {toast} from 'react-toastify';

function Contact(){
    const [loading, setLoading] = useState(false);
    const onSubmit = (value, reset) => {
        setLoading(true)
        CreateContactPI(value).then((res) => {
            if (res) {
                reset()
                toast('Successfully sent', {
                    type: 'success'
                })
                setLoading(false)
            }
        }).catch((error) =>
            toast('Failed', {
                type: 'error'
            })
        )
    };

    return(
        <div className='shadow-sm px-[24px] py-[32px] rounded-[8px] w-[40%]'>
            <Formik
                validationSchema={CONTACT_SCHEMA}
                initialValues={{name: '', email: '', message: ''  }}
                onSubmit={(values, {resetForm}) => onSubmit(values, resetForm)} >
                {({handleSubmit, handleChange, values, handleBlur, touched, errors,}) => (
                    <div className='flex flex-col gap-[20px]'>
                        <Input name='name' placeholder='Name' handleChange={handleChange} onBlur={handleBlur} value={values.name} error={touched.name && !!errors.name && errors.name} />
                        <Input name='email' placeholder='Email' handleChange={handleChange} onBlur={handleBlur} value={values.email} error={touched.email && !!errors.email && errors.email} />
                        <TextArea name='message' placeholder='Message' handleChange={handleChange} onBlur={handleBlur} value={values.message} error={touched.message && !!errors.message && errors.message} />
                        <button disabled={loading} className={`w-full py-[12px] rounded-[8px] ${loading? 'bg-disabled' : 'text-light bg-primary'}`} type='button' onClick={handleSubmit}>Send Message</button>
                    </div>
                )}
            </Formik>
        </div>
    )
}

export default Contact;
