import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import {FaQuoteRight, FaRegUser} from 'react-icons/fa';
import { useEffect, useState } from 'react';

function Testimonial({ person }) {
    const [slidesPerView, setSlidesPerView] = useState(1);

    useEffect(() => {
        const handleResize = () => {
            const newSlidesPerView = window.innerWidth >= 1200 ? 3 : window.innerWidth >= 768 ? 2: 1;
            setSlidesPerView(newSlidesPerView);
        };
        handleResize();
        window.addEventListener('resize', handleResize, { passive: true });
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return(
        <Swiper
            style={{ height: '100%' }}
            slidesPerView={slidesPerView}
            mousewheel={false}
            spaceBetween={30}
            freeMode={false}
            pagination={{
                clickable: true,
            }}
            modules={[Pagination]}
        >
            {
                person.map(({ id, name, description, profile, title }) => (
                    <SwiperSlide className='py-[12px]' key={id}>
                        <div className='rounded-[4px] p-[12px] md:p-[24px] shadow bg-white'>
                            <div className='flex gap-[8px]'>
                                <div className='w-auto xl:w-[25%]'>
                                    <div className='flex justify-center items-center md:h-[72px] md:w-[72px] h-[40px]
									w-[40px] border-primary border-2 rounded-full overflow-hidden'>
                                        {
                                            profile.length? <img
                                                src={profile}
                                                alt='avatar'
                                                className='object-cover rounded-full h-full w-full'
                                            /> : <FaRegUser className='text-[32px] text-disabled' />
                                        }
                                    </div>
                                </div>
                                <div className='flex flex-col justify-center w-[50%]'>
                                    <span className='text-[12px] md:text-[16px] '>{name}</span>
                                    <span className='text-[10px] md:text-[14px]
									font-[400] text-typography-700'>{title}</span>
                                </div>
                                <div className='flex justify-end w-[25%] text-[40px] text-primary'>
                                    <FaQuoteRight />
                                </div>
                            </div>
                            <div className='mt-[24px]'>
								<span className='font-[400] text-typography-700 text-[12px]'>
									{
                                        description?.slice(0, 80)+'...'
                                    }
								</span>
                                <div className=' flex justify-center w-full mt-[12px]'>
                                    <button className='h-[34px] bg-transparent text-primary'> Read More</button>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                ))
            }
        </Swiper>
    );
}

export default Testimonial;
