import * as Yup from 'yup';

export const PRODUCT_SCHEMA = Yup.object().shape( {
    title: Yup.string()
        .required('Title is required'),
    price: Yup.string()
        .required( 'Price is required' ),

    brand: Yup.string()
        .required('Brand Number is required'),
    description: Yup.string()
        .required('Description is required').min(10, 'Message must contain 10 ')
} );
