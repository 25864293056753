import image from '../../assets/image/forte-foundry-x1vFmsaZbSw-unsplash.jpg'
import image2 from '../../assets/image/teacreativelife-soo-chung-yjx04_K2KCM-unsplash.jpg'

function AboutUs() {
    return(
        <div className='lg:px-[122px] px-[24px] my-[48px]'>
            <div className='flex gap-[24px]'>
                <div className='w-full md:w-[50%]'>
					<span className='font-bold text-[24px] text-primary'>
					Sattva Bath Salt
					</span>
                    <div className='mt-[12px]'>
						<span className='font-light text-primary'>
						Our Story
						</span>
                        <div className='py-[12px]'>
							<div className='text-[16px] leading-loose text-typography-700'>
								<p>
									Sattva means cleansing/purifying.Sattva Bath Salt is home based.
								It is being blessed and charged with divine blessings to cleanse the
								unwanted energies and recharging with  healing energies.  The product's
								composition are Epsom, Pink Himalayan Salt, Local herbs and flowers which
								are collected from local community and fragrances(essential oils).
								</p>
								<p>
									Generally the bath salt was used from ancients(in seventeen century)
								for home remedies. The first discovery was with constipation treatment.
								This salt is not to be used in cooking.
								</p>
								<p>
									Sattva Bath Salt can help <b className='text-typography-800'> to stimulate circulation, ease muscle cramps, help
								relieve stiffness in joints, helps for migraine,aid with arthritis or back
								pain, and soothe achy, overworked legs and feet.</b> It helps in cleansing and
								detox the body.

								</p>
							</div>
                            <div className='mt-[12px]'>
                                <p className='font-bold'>Release|Relax|Rejuvenate</p>
                                <p className='font-bold'>Your contribution helps build our community.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-[50%] hidden md:flex justify-end items-end '>
                    <div className='w-[480px] h-[340px] shadow rounded-[8px]'>
                        <img src={ image}
                               alt='blog-image' className='object-cover h-full w-full rounded-[8px]'/>
                    </div>
                </div>
            </div>

            <div className='flex flex-row-reverse gap-[24px] mt-[54px]'>
                <div className='w-full md:w-[50%] '>
					<span className='font-bold text-[24px] text-primary'>
					Sattva Herbal Tea
					</span>
                    <div className='mt-[12px]'>
						<span className='font-light text-primary'>
						Our Story
						</span>
                        <div className='py-[12px]'>

							<span className='text-[16px] text-typography-700'>
								Embark on a transformative journey into the boundless realms
								of vitality and well-being, guided by the synergistic embrace
								of the dynamic duo – Cordyceps and Herbal Tea. Cordyceps,
								esteemed as a medicinal fungus deeply rooted in traditional
								Chinese medicine, gracefully converges with the time-honored
								elixir of herbal tea. This elixir, crafted from nature botanical
								treasures and cherished over centuries, weaves a tapestry of health and
								flavor, inviting you to experience a harmonious symphony that nourishes
								both the body and the senses. Discover the invigorating power and rich
								nuances of this extraordinary combination as you immerse yourself in
								a holistic fusion of ancient wisdom and natural abundance.
							</span>

                        </div>
                    </div>
                </div>
                <div className=' w-[50%] hidden md:flex justify-start items-end '>
                    <div className='w-[480px] h-[340px] shadow rounded-[8px]'>
                        <img src={ image2 }
                               alt='blog-image' className='object-cover h-full w-full rounded-[8px]'/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutUs;
