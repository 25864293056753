import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import {createBlogAPi, GetBlogAPi} from '../../services/blog-service/blog.api';
import {toast} from 'react-toastify';

export const BLOG_KEY = 'blogs';

export const BLOG_INITIAL_STATE = {
    blogs: [],
    isLoading: false
};

export const getBlogData = createAsyncThunk(
    'blogs/getBlogData',
    async ( _, thunkAPI ) => {
        const response = await GetBlogAPi();
        if ( response ) {
            return response;
        }
        if ( !response.length ) {
            return thunkAPI.rejectWithValue('error' );
        }
    }
);

export const createBlog = createAsyncThunk(
    'products/createBlog',
    async ( payload,_, thunkAPI ) => {
        const response = await createBlogAPi(payload);
        if ( response ) {
            toast('Blog Created', {
                type: 'success'
            })
            return response;
        }
        if ( !response.length ) {
            return thunkAPI.rejectWithValue('error' );
        }
    }
);

export const blogSlice = createSlice({
    name: BLOG_KEY,
    initialState: BLOG_INITIAL_STATE,
    reducers: {},
    extraReducers: (builder) =>{
        builder
            .addCase(getBlogData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getBlogData.fulfilled, ( state, action ) => {
                state.blogs = action.payload;
                state.isLoading = false;
            } )
            .addCase(getBlogData.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(createBlog.pending, (state) => {
                state.isLoading = true
            })
            .addCase(createBlog.fulfilled, (state) => {
                state.isLoading = false
            })
            .addCase(createBlog.rejected, (state) => {
                state.isLoading = false
            })
        ;
    }
});

export const blogReducer = blogSlice.reducer;

export const getBlog = (rootState) => rootState[BLOG_KEY];
export const selectBlogs = createSelector(getBlog, (state) => state.blogs);
export const selectIsLoading = createSelector(getBlog, (state) => state.isLoading);
