export const BENEFIT_LIST = [
    {
        id: 1,
        section: [
            'Herbs often contain compounds with healing benefits.',
            'Herbs are rich in essential vitamins, minerals, and antioxidants.',
            'Traditional medicine systems use herbs for various health issues.',
            'Herbs add depth to dishes without excess salt or sugar.',
            'Many herbs contribute to mood upliftment and stress reduction.',
            'Some herbs soothe digestive discomfort and promote gastrointestinal health.',
            'Certain herbs have compounds beneficial for skin health.'
        ]
    },
    {
        id: 2,
        section: [
            'Herbs like rosemary oil are used to stimulate hair growth.',
            'Herbs offer diverse flavors, enhancing various cuisines.',
            'Growing herbs at home is eco-friendly and cost-effective.',
            'Some herbs, like ginger, possess anti-inflammatory properties.',
            'Herbs like chamomile are used for their calming effects.',
            'Herbs like thyme can be beneficial for respiratory health.',
            'Certain herbs contribute to overall immune system function.'
        ]
    }
];
