export const DUMMY_TESTIMONIAL = [
    {
        id: 1,
        name: 'Customer',
        title: 'Unknown',
        description: 'Sattva salt helped me to cleanse my infection. It wipes in two soaks.',
        profile: '',
    },{
        id: 2,
        name: 'Customer 2 Bhutan',
        title: 'Unknown',
        description: 'After I soak for 10 minutes, I felt how it helped me reduce inflammation. Worth the buy.',
        profile: '',
    },
    {
        id: 3,
        name: 'Customer Thimphu',
        title: 'Unknown',
        description: 'After doctor prescribed me to soak in bath salt. It helped my hemorrhoids. ',
        profile: '',
    },
    {
        id: 4,
        name: 'Herbalist customer Herbalism',
        title: 'Unknown',
        description: 'I used your bathsalt during my shower, it\'s helpful indeed... I was having back ache and felt better after a full moon bathsalt cleanse. Thank You so much Sattva Cleansing. 🤲💖🥲👋',
        profile: '',
    },

];
