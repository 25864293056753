import {addDoc, collection, deleteDoc, doc, getDoc, getDocs, orderBy, query} from 'firebase/firestore';
import {db} from '../../firebase/firebase.config';
import {FetchSingleProduct} from '../product-service/product.api';

export const FetchOrderAPI = async () => {
    const orderRef = collection(db, 'orders');
    try {
        const q = query(orderRef, orderBy('createAt', 'desc'));
        const querySnapshot = await getDocs(q);
        const orders = [];
        for (const doc of querySnapshot.docs) {
           orders.push({ id: doc.id, ...doc.data() })
        }
        return (orders)
    } catch (error) {
        return error
    }
}

export const FetchSingleOrder = async (orderId) => {
    try {
        const orderDoc = doc(db, 'orders', orderId);
        const orderSnapshot = await getDoc(orderDoc);
        if (orderSnapshot.exists()) {
            return { id: orderSnapshot.id, ...orderSnapshot.data() };
        }
        return null;
    } catch (error) {
        return(error)
    }
}

export const FetchOrderDetailAPI = async (id) => {
    try {
        const order = await FetchSingleOrder(id);
        const productPromises = order.products.map(async (item) => {
            const product = await FetchSingleProduct(item.productId);
            return { ...product, ...item };
        });
        const products = await Promise.all(productPromises);
        return {
            ...order,
            products
        }
    } catch (error) {
        return error
    }
}


export const CreateOrderAPI = async (data) => {
    try {
        await addDoc(collection(db, 'orders'), {...data});
        return { success: true };
    } catch (error) {
        return { success: false, error: error.message };
    }
}

export const DeleteOrderAPI = async (id) => {
   try {
       const orderRef = doc(db, 'orders', id);
       await deleteDoc(orderRef);
       return { success: true };
   } catch (error) {
       return { success: false }
   }
}
