import CartPricing from '../../components/cart/cart-price';
import CartBill from '../../components/cart/cart-bill';

function Cart() {
    return(
        <div className='flex flex-col md:flex-row px-[24px] xl:px-[122px] my-[40px] gap-[12px] lg:gap-[38px]'>
            <CartPricing/>
            <CartBill />
        </div>
    );
}

export default Cart;
