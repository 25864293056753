import { createAction, createSelector, createSlice } from '@reduxjs/toolkit';
import {addItem, removeItems, removeItem, getPrice} from './cart.utils';

export const CART_KEY = 'cart';

export const CART_INITIAL_STATE = {
	cart: [],
};

export const cartSlice = createSlice({
	name: CART_KEY,
	initialState: CART_INITIAL_STATE,
	reducers: {},
	extraReducers: (builder) =>{
		builder
			.addCase(addToCart, ( state, action ) => {
				state.cart = addItem( state.cart, action.payload);
			} )
			.addCase(removeFromCart, (state, action) => {
				state.cart = removeItem(state.cart, action.payload);
			})
			.addCase(removeAllItem, (state, action) => {
				state.cart = removeItems(state.cart, action.payload);
			})
			.addCase(emptyCart, (state) => {
				state.cart = []
			})
		;
	}
});

export const addToCart = createAction( 'addToCart', args => ({ payload: args }) );
export const removeFromCart = createAction('removeFromCart', args => ( { payload: args }) );
export const removeAllItem = createAction('removeAllItem', args => ({ payload: args }));
export const emptyCart = createAction('emptyCart', args => ({ payload: args }));

export const cartReducer = cartSlice.reducer;

export const getCart = (rootState) => rootState[CART_KEY];
export const selectCart = createSelector(getCart, (state) => state.cart);
export const selectCartTotal = createSelector( getCart, (state) => state.cart.reduce(
	( accumulatedQuantity,
	  cartItems ) => accumulatedQuantity + (cartItems.quantity ? cartItems?.quantity : 0), 0
) );
export const selectCartPriceTotal = createSelector( getCart, (state) => state.cart.reduce(
	( accumulatedQuantity, cartItems ) =>
		accumulatedQuantity + (
			cartItems.quantity ?
				cartItems?.quantity : 0) *
		(getPrice(cartItems?.price && cartItems?.price
			, cartItems?.price)), 0)
);
