import {useDispatch, useSelector} from 'react-redux';
import {emptyCart, selectCart, selectCartPriceTotal} from '../../redux/cart/cart.slice';
import noData from '../../assets/image/no-data-pana.png';
import Input from '../../components/shared/input/input';
import {Formik} from 'formik';
import {CHECKOUT_SCHEMA} from './misc/product.validation';
import {CreateOrderAPI} from '../../services/order/order.service';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {phoneVerify} from '../../shared/helper';

function Checkout() {
    const cartItem = useSelector(selectCart)
    const total = useSelector(selectCartPriceTotal);
    const [loader, setLoader] = useState(false);
    const route = useNavigate();
    const dispatch = useDispatch();
    const [valid, setIsValid] = useState(false);

    useEffect(() => {
        if (!total || !cartItem?.length ) {
            route('/shop')
        }
    }, [])
    const handleClick = (value) => {
        setLoader(true)
        const data = {
            ...value,
            total,
            createAt: new Date(),
            products: cartItem?.map((item) =>{
             return {
                 productId: item?.id,
                 price: item?.price,
                 quantity: item?.quantity
             }
            }),
        }
        CreateOrderAPI(data).then((res) => {
            if (res) {
                toast('Order placed successfully! 😁', {
                    type: 'success'
                });
                setLoader(false)
                dispatch(emptyCart())
                route('/shop');
            }
        }).catch((error) => {
           if (error) {
               toast('Something went wrong 😢', {
                   type: 'error'
               });
           }
        })
    }
    return(
        <div className='flex flex-col md:flex-row px-[24px] xl:px-[122px] my-[40px] gap-[12px] lg:gap-[38px]'>
            <div className='border border-secondary p-[12px] md:p-[24px] h-fit rounded-[8px] w-full md:w-[70%]'>
                {
                    !cartItem.length ?
                        <div className='flex w-full justify-center items-center'>
                            <div className=' w-[250px] h-[250px]'>
                                <img src={noData} alt='no data' className='opacity-60'/>
                            </div>
                        </div>:
                        cartItem.map((item) => (
                            <div className='flex flex-wrap md:flex-nowrap py-[24px] border-secondary border-b' key={item?.id}>
                                <div className='md:h-[100px] md:w-[120px] w-full h-[240px]'>
                                    <img src={item?.image} alt='product' className='h-full w-full rounded-[4px] object-contain'/>
                                </div>
                                <div className='flex w-full flex-col md:flex-row gap-[24px] md:gap-0 md:mt-0 mt-[12px]'>
                                    <div className='flex flex-col justify-center md:ml-[34px] gap-[12px] md:w-[50%] w-full'>
                                        <p className='font-light'>{item?.title}</p>
                                        <p className='font-light text-[12px]'>Size: { item?.size || '_' }</p>
                                        <p className='font-light text-[12px]'>
                                            {item?.description.slice(0,100)}
                                        </p>
                                    </div>
                                    <div className='flex gap-[12px] justify-center items-center md:ml-[34px] md:w-[25%] w-full'>
                                        <span>{item?.quantity}</span>
                                    </div>
                                    <div className='flex flex-col md:ml-[34px] md:items-end md:w-[25%] w-full items-center'>
                                        <span>Nu {item?.price}</span>
                                    </div>
                                </div>
                            </div>
                        ))
                }
                <div className='mt-[24px] flex w-full bg-secondary py-[12px] px-[24px] justify-between rounded-[8px]'>
				<span className='font-bold'>
						Subtotal
				</span>
                    <span className='font-bold'>
						Nu {total}
				</span>
                </div>
            </div>
            <div className='h-fit p-[12px] md:p-[24px] rounded-[8px] bg-light w-full md:w-[30%] gap-[16px] shadow'>
                <span className='font-light'>Fill The Details</span>
                <div className='mt-[16px] font-light text-typography-700'>
                    <Formik
                        validationSchema={CHECKOUT_SCHEMA}
                        validate= {(value) => {
                            const phoneNumber = `+975${value.phone}`;
                            const isValid = phoneVerify(phoneNumber);
                            setIsValid(!isValid);
                            return isValid;
                        }}
                        initialValues={{ name: '', email: '', phone: '', address: '' }}
                        onSubmit={(values) => handleClick(values)}
                    >
                        {({handleChange, handleSubmit, handleBlur, values, touched, errors}) => (
                            <div className='flex flex-col w-full gap-[12px]'>
                                <Input name='name' value={values.name} placeholder='Name' handleChange={handleChange} onBlur={handleBlur} error={touched.name && !!errors.name && errors.name}/>
                                <Input name='email' value={values.email} placeholder='Email' handleChange={handleChange} onBlur={handleBlur} error={touched.email && !!errors.email && errors.email} />
                                <Input name='phone' value={values.phone} placeholder='Phone' handleChange={handleChange} onBlur={handleBlur}
                                       error={(!!errors.phone && touched.phone) ? errors.phone : (values.phone.length && !valid) ? 'Phone number is invalid' : undefined}/>
                                <Input name='address' value={values.address} placeholder='Address' handleChange={handleChange} onBlur={handleBlur} error={touched.address && !!errors.address && errors.address} />
                                <div className='flex justify-between mt-[16px] '>
                                    <span className='text-typography-900 font-bold'>Total Amount</span>
                                    <span className='text-typography-900 font-bold'>Nu {total}</span>
                                </div>
                                <button type='button' disabled={loader} onClick={handleSubmit}
                                        className={`w-full mt-[24px] py-[12px] rounded-[8px] ${ loader ? 'bg-secondary text-dark' : 'text-light bg-primary text-white'}`}>
                                    PLACE ORDER
                                </button>
                            </div>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default Checkout;
