import * as Yup from 'yup';

export const CONTACT_SCHEMA = Yup.object().shape( {
    email: Yup.string()
        .required('Email is required').email('Email should be valid'),
    name: Yup.string()
        .required('Name is required'),
    message: Yup.string()
        .required( 'Message is required' ).min(1, 'Required minimum of 100 words'),
} );
