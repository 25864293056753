import { Formik } from 'formik';
import {useEffect, useRef, useState} from 'react';
import Input from '../../components/shared/input/input';
import TextArea from '../../components/shared/text-area/text-area';
import {useDispatch, useSelector} from 'react-redux';
import {createProduct, selectIsLoading, updateProduct} from '../../redux/product/product.slice';
import {PRODUCT_SCHEMA} from './mics/validation/product.validation';
import {useParams} from 'react-router-dom';
import {FetchSingleProduct} from '../../services/product-service/product.api';
import {toast} from 'react-toastify';

function ProductCreation() {
    const fileInputRef = useRef(null);
    const [product, setProduct] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const id = useParams()['id'];
    const [preview, setPreview] = useState('');
    const dispatch = useDispatch();
    const loading = useSelector(selectIsLoading);

    useEffect(() => {
       if (id) {
           const getProduct = async () => {
               const data = await FetchSingleProduct(id);
               setProduct(data)
           }
           getProduct();
       }
    }, [id]);

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        const fileUri = URL.createObjectURL(file);
        setPreview(fileUri);
    };

    const handleSubmit = (value, resetForm) => {
        const data = {
            ...value,
            image: id ? product.image : selectedFile,
        }
        if (!!selectedFile ) {
            dispatch(createProduct(data))
            resetForm()
            return
        }
        if (id) {
            const update = {
                ...data,
                id: product.id
            }
            dispatch(updateProduct(update))
            return;
        }
        toast('Image file is missing 🥹', {
            type: 'error'
        })
    }

    return(
        <div className='flex justify-center items-center px-[122px] my-[48px]'>
            {
                id && !product ? <>loading</> :
                    <div className='w-[50%] shadow py-[24px] px-[16px] rounded-[8px]'>
                        <span className='font-bold'>Product Creation</span>
                        <Formik
                            validationSchema={PRODUCT_SCHEMA}
                            initialValues={{title: product?.title || '', price: product?.price || '', size: product?.size || '', description: product?.description || '', brand: product?.brand || ''}}
                            onSubmit={(values, {resetForm}) => handleSubmit(values, resetForm)}>
                            {({ handleChange, values, handleSubmit, errors, touched, handleBlur }) => (
                                <div className='my-[24px]'>
                                    <div className='flex gap-[24px]'>
                                        <Input name='title'
                                               placeholder='Title'
                                               value={values.title}
                                               onBlur={handleBlur}
                                               error={touched.title && !!errors.title && errors.title}
                                               handleChange={handleChange}  />
                                        <Input name='price'
                                               placeholder='Product Price'
                                               value={values.price}
                                               handleChange={handleChange}
                                               onBlur={handleBlur}
                                               error={touched.price && !!errors.price && errors.price}
                                        />
                                    </div>
                                    <div className='flex gap-[24px] mt-[12px]'>
                                        <Input name='size'
                                               placeholder='Product size'
                                               value={values.size}
                                               handleChange={handleChange}
                                               onBlur={handleBlur}
                                               error={touched.size && !!errors.size && errors.size}
                                        />
                                        <Input name='brand'
                                               placeholder='Product brand'
                                               value={values.brand}
                                               onBlur={handleBlur}
                                               error={touched.brand && !!errors.brand && errors.brand}
                                               handleChange={handleChange} />
                                    </div>
                                    {
                                        id ? null : <div className='flex mt-[12px] h-[130px] w-full border-2 border-primary px-[24px] py-[12px] rounded-[8px] items-center justify-between'>
                                            <input className='hidden' ref={fileInputRef} type='file' onChange={handleFileChange}/>
                                            <button className='border-primary border-2 rounded-[8px] px-[12px] ' onClick={() => handleButtonClick()}>Choose Image</button>
                                            <div className='flex items-center h-[120px] w-[120px] overflow-hidden '>
                                                {
                                                    preview && 	<img src={preview} alt='preview'
                                                                       className='object-cover h-full w-full'/>
                                                }
                                            </div>
                                        </div>
                                    }
                                    <div className='mt-[12px]'>
                                        <TextArea name='description'
                                                  placeholder='Write description here...'
                                                  value={values.description}
                                                  error={touched.description && !!errors.description && errors.description}
                                                  handleChange={handleChange} />
                                    </div>
                                    <div className='mt-[12px]'>
                                        <button disabled={loading} type='submit' className={`px-[8px] py-[4px] rounded-[8px] ${loading ? 'bg-disabled text-light ' : 'bg-primary text-[white]'}`} onClick={handleSubmit}>
                                            {loading ? 'Uploading...' : 'Upload'}
                                        </button>
                                    </div>
                                </div>
                            )}
                        </Formik>
                    </div>
            }
        </div>
    );
}

export default ProductCreation;
