import {addDoc, collection, deleteDoc, doc, getDocs, orderBy, query} from 'firebase/firestore';
import {db} from '../../firebase/firebase.config';

export const CreateContactPI = async (contactData) => {
    try {
        await addDoc(collection(db, 'contact'), {
                ...contactData,
                createAt: new Date(),
            });
        return { success: true };
    } catch (error) {
        return { success: false, error: error.message };
    }
}

export const GetContactAPI = async () => {
    const productsRef = collection(db, 'contact');
    try {
        const q = query(productsRef );
        const querySnapshot = await getDocs(q);
        const contact = [];
        querySnapshot.forEach((doc) => {
            contact.push({ id: doc.id, ...doc.data() });
        });
        return contact;
    } catch (error) {
        return error
    }
}

export const DeleteContactAPI = async (id) => {
    try {
        const orderRef = doc(db, 'contact', id);
        await deleteDoc(orderRef);
        return await GetContactAPI();
    } catch (error) {
        return { success: false }
    }
}
