import {DUMMY_TESTIMONIAL} from '../../components/home-page/testimonial/constant/testimonial';
import Testimonial from '../../components/home-page/testimonial/testimonial';
import BlogSection from '../../components/home-page/blog-section/blog-section';
import LandingSwapper from '../../components/home-page/swapper/swapper';
import BenefitSection from '../../components/home-page/benefit-section/benefit-section';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    getProductFeaturedData,
    selectFeaturedProducts, selectIsLoading,
} from '../../redux/product/product.slice';
import ProductCard from '../../components/shared/product-card/product-card';
import {useNavigate} from 'react-router-dom';
import noData from '../../assets/image/no-data-pana.png';
import ProductLoader from '../../components/shared/loader/product.loader';
import Contact from '../../components/home-page/contact/contact';

function HomePage() {
    const route = useNavigate();
    const dispatch = useDispatch();
    const products = useSelector(selectFeaturedProducts);
    const isLoading = useSelector(selectIsLoading);
    useEffect(() => {
        if (!products?.length) {
            dispatch(getProductFeaturedData());
        }
    }, []);
    return (
        <div className="w-full overflow-x-hidden">
            <div className="flex h-screen px-[24px] lg:px-[122px] pb-[64px] w-full bg-light lg:gap-[18px]">
                <div className="h-full flex flex-col justify-center item xl:w-[50%]">
                    <p className="text-primary text-[20px] lg:text-[50px] font-[800]">Bhutan Bath Essentials</p>
                    <div className="w-[80%] my-[24px]">
                        <p className="text-typography-700 text-[14px]">
                            We aim to provide 10% of made profits to environment
                            & charities in order to help the world we live in!
                        </p>
                    </div>
                    <div className="flex gap-[24px]">
                        <button
                            className="rounded-[24px] bg-primary text-[12px] text-white text-light px-[12px]  md:px-[16px] py-[12px] "
                            onClick={() => route('/shop')}>
                            SHOP NOW
                        </button>
                        <button
                            className="rounded-[24px] bg-transparent text-[12px] border-2 border-primary text-primary px-[12px] md:px-[16px] py-[12px]"
                            onClick={() => route('/blogs')}>
                            SEE DETAILS
                        </button>
                    </div>
                </div>
                <div className="hidden xl:flex h-full items-center w-[50%] justify-end">
                    <div className="border-t-2 border-r-2 border-primary
					 h-[40%] lg:h-[80%] pt-[14px] pr-[20px] relative">
                        <div className="flex flex-col justify-center
					items-center absolute bg-primary h-[80px] w-[80px] z-10
					rotate-45
					 top-[-30px] right-[-30px] rounded-[50%]"
                        >
                            <p className="font-bold text-light">20%</p>
                            <p className="text-light font-thin text-[18px]">SALE!</p>
                        </div>
                        <div className="border-4 border-primary h-full lg:w-[480px] shadow">
                            <LandingSwapper/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="my-[56px] px-[24px] xl:px-[122px]">
                <div className="flex flex-col items-center">
                    <span className="text-primary md:text-[18px]">Popular</span>
                    <span className="text-[24px] text-header lg:text-[48px] font-[700]">Featured Products</span>
                </div>
                <div className="flex flex-wrap justify-center lg:justify-start">
                    {
                       isLoading ? <ProductLoader/> : (
                           products?.length ?
                               products?.map((item) => (
                                   <div className="my-[12px] px-2 w-full md:w-1/2 lg:w-1/3 xl:w-1/4" key={item?.id}>
                                       <ProductCard item={item}/>
                                   </div>
                               )) : <div className="flex w-full justify-center items-center">
                                   <div className=" w-[250px] h-[250px]">
                                       <img src={noData} alt="no data" className="opacity-60"/>
                                   </div>
                               </div>
                       )
                    }
                    <div className="flex w-full justify-center mt-[12px]">
                        {
                            products?.length ?
                                <button className="bg-primary rounded-full text-light px-[16px] py-[12px]"
                                        onClick={() => route('/shop')}>
                                    View Shop
                                </button> : null
                        }
                    </div>
                </div>
            </div>
            <div className="my-[56px] px-[24px] xl:px-[122px] bg-cover bg-center ">
                <BlogSection/>
            </div>
            <div className="my-[56px]">
                <div className="flex flex-col items-center xl:px-[122px]">
                    <span className="text-primary md:text-[18px]">Testimonial</span>
                    <span className="text-[24px] lg:text-[48px] font-[700]">What Our Customer Say</span>
                </div>
                <div className="py-[24px] px-[24px] h-[400px] xl:px-[122px] bg-purple-50">
                    <Testimonial person={DUMMY_TESTIMONIAL}/>
                </div>
            </div>
            <div className="my-[56px] px-[24px] xl:px-[122px]">
                <div className="flex flex-col items-center">
                    <span className="text-primary md:text-[18px]">Why Us</span>
                    <span className="text-[24px] lg:text-[48px] font-[700]">The Benefit of Herb</span>
                </div>
                <BenefitSection/>
            </div>
            <div className="my-[56px] px-[24px] xl:px-[122px]">
                <div className="flex flex-col items-center ">
                    <span className="text-primary md:text-[18px]">Contact Us</span>
                    <span className="text-[24px] lg:text-[48px] font-[700]">Get In Touch</span>
                </div>
                <div className="flex justify-center py-[24px]">
                    <Contact />
                </div>
            </div>
        </div>
    );
}

export default HomePage;
