import * as Yup from 'yup';

export const CHECKOUT_SCHEMA = Yup.object().shape( {
    name: Yup.string()
        .required('Name is required').min(2, 'Name too short'),
    email: Yup.string()
        .required( 'Email is required' ).email('Invalid email'),
    phone: Yup.number()
        .required('Phone is required').min(8, 'Number too short'),
    address: Yup.string()
        .required('Address is required')
} );
