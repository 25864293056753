import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import {
    CreateProductAPI, DeleteProductAPI,
    FetchProductAPI,
    FetchProductFeaturedAPI, UpdateProductAPI
} from '../../services/product-service/product.api';
import {toast} from 'react-toastify';

export const PRODUCT_KEY = 'products';

export const PRODUCT_INITIAL_STATE = {
    products: undefined,
    featuredProducts: [],
    isLoading: false
};

export const getProductData = createAsyncThunk(
    'products/getProducts',
    async ( payload, _, thunkAPI ) => {
        const response = await FetchProductAPI(payload);
        if ( response ) {

            return response;
        }
        if ( !response.length ) {
            return thunkAPI.rejectWithValue('error' );
        }
    }
);

export const getProductFeaturedData = createAsyncThunk(
    'products/getProductFeaturedData',
    async ( _, thunkAPI ) => {
        const response = await FetchProductFeaturedAPI();
        if ( response ) {
            return response;
        }
        if ( !response.length ) {
            return thunkAPI.rejectWithValue('error' );
        }
    }
);

export const createProduct =  createAsyncThunk(
    'products/createProduct',
    async ( payload, _, thunkAPI ) => {
        const response = await CreateProductAPI(payload);
        if ( response ) {
            toast('Product Created', {
                type: 'success'
            })
            return response;
        }
        if ( !response.length ) {
            return thunkAPI.rejectWithValue('error' );
        }
    }
);

export const updateProduct =  createAsyncThunk(
    'products/updateProduct',
    async ( payload, _, thunkAPI ) => {
        const response = await UpdateProductAPI(payload);
        if ( response ) {
            toast('Product Updated', {
                type: 'success'
            })
            return response;
        }
        if ( !response.length ) {
            return thunkAPI.rejectWithValue('error' );
        }
    }
);

export const deleteProduct = createAsyncThunk(
    'products/deleteProduct',
    async ( payload, _, thunkAPI ) => {
        const response = await DeleteProductAPI(payload);
        if ( response ) {
            return response?.data;
        }
        if ( !response.length ) {
            return thunkAPI.rejectWithValue('error' );
        }
    }
);

export const productSlice = createSlice({
    name: PRODUCT_KEY,
    initialState: PRODUCT_INITIAL_STATE,
    reducers: {},
    extraReducers: (builder) =>{
        builder
            .addCase(getProductData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getProductData.fulfilled, ( state, action ) => {
                state.products = action.payload;
                state.isLoading = false;
            } )
            .addCase(getProductData.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(getProductFeaturedData.fulfilled, (state, action) => {
                state.featuredProducts = action.payload
            })
            .addCase(createProduct.pending, (state) => {
                state.isLoading = true
            })
            .addCase(createProduct.fulfilled, (state, action) => {
                state.isLoading = false
            })
            .addCase(createProduct.rejected, (state) => {
                state.isLoading = false
            })
            .addCase(deleteProduct.pending, (state,action) => {
                state.isLoading = true
            })
            .addCase(deleteProduct.fulfilled, (state, action) => {
                state.products = action.payload;
               state.isLoading = false;
            })
            .addCase(deleteProduct.rejected, (state) => {
                state.isLoading = false
            })
            .addCase(updateProduct.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(updateProduct.fulfilled, (state, action) => {
                state.isLoading = false
            })
            .addCase(updateProduct.rejected, (state, action) => {
                state.isLoading = false
            })
        ;
    }
});

export const productReducer = productSlice.reducer;

export const getProducts = (rootState) => rootState[PRODUCT_KEY];
export const selectProducts = createSelector(getProducts, (state) => state.products);
export const selectFeaturedProducts = createSelector(getProducts, (state) => state.featuredProducts);
export const selectIsLoading = createSelector(getProducts, (state) => state.isLoading);
