import * as libphonenumber from 'google-libphonenumber';

export const phoneVerify = (value) => {
    const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
    const message = 'Phone number is invalid';
    let phone;
    try {
        phone = phoneUtil.parseAndKeepRawInput(value);
    } catch {
        return message;
    }
    return phoneUtil.isValidNumber(phone) ? '' : message;
};
