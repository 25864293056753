import {collection, getDocs, doc, getDoc, addDoc} from 'firebase/firestore';
import {db} from '../../firebase/firebase.config';
import {uploadImage} from '../shared/shared.api';

export const GetBlogAPi = async () => {
    const blogRef = collection(db, 'blogs');
    try {
        const querySnapshot = await getDocs(blogRef);
        const blogs = [];
        querySnapshot.forEach((doc) => {
            blogs.push({ id: doc.id, ...doc.data() });
        });
        return blogs;
    } catch (error) {
        console.log(error)
        return error
    }
}

export const GetSingleBlogAPi = async (id) => {
    const blogRef = doc(db, 'blogs', id);
    try {
        const blogSnapShot = await getDoc(blogRef);
        if (blogSnapShot.exists()) {
            return { id: blogSnapShot.id, ...blogSnapShot.data() };
        }
        return null;
    } catch (error) {
        console.log(error)
        return error
    }
}

export const createBlogAPi = async (productData) => {
    try {
        const upload = {
            blob: productData.image,
            path: '/blogs'
        }
        const image = await uploadImage(upload);
        if (image.message) {
            await addDoc(collection(db, 'blogs'), {
                ...productData,
                createAt: new Date(),
                image: image.message
            });
        }
        return { success: true };
    } catch (error) {
        return { success: false, error: error.message };
    }
}
