import noData from '../../assets/image/no-data-pana.png';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from 'react';
import {contactDelete, getContactData, selectContact, selectIsLoading} from '../../redux/contact/contact.slice';

function Contact() {
    const dispatch = useDispatch();
    const contactList = useSelector(selectContact);
    const loading = useSelector(selectIsLoading);
    useEffect(() => {
       dispatch(getContactData())
    }, [])
    const handleDelete = (id) => {
        dispatch(contactDelete(id))
    }
    return(
        <div className='flex flex-col md:flex-row px-[24px] xl:px-[122px] my-[40px] gap-[12px] lg:gap-[38px]'>
            <div className='border border-secondary p-[12px] md:p-[24px]  rounded-[8px] w-full md:w-full'>
                {
                    !contactList?.length ?
                        <div className='flex w-full justify-center items-center'>
                            <div className=' w-[250px] h-[250px]'>
                                <img src={noData} alt='no data' className='opacity-60'/>
                            </div>
                        </div>:
                        <div className="flex flex-col">
                            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                                    <div className="overflow-hidden">
                                        <table className="min-w-full text-left text-sm font-light">
                                            <thead className="border-b font-medium dark:border-neutral-500">
                                            <tr>
                                                <th scope="col" className="px-6 py-4">Contact Id</th>
                                                <th scope="col" className="px-6 py-4">Name</th>
                                                <th scope="col" className="px-6 py-4">Email</th>
                                                <th scope="col" className="px-6 py-4">Message</th>
                                                <th scope="col" className="px-6 py-4">Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                contactList.map((item) => (
                                                    <tr className="border-b dark:border-neutral-500 cursor-pointer" key={item.id}>
                                                        <td className=" px-6 py-4 font-medium">{item.id}</td>
                                                        <td className=" px-6 py-4">{item.email}</td>
                                                        <td className=" px-6 py-4">{item.name}</td>
                                                        <td className="px-6 py-4">{item.message}</td>
                                                        <td className="whitespace-nowrap px-6 py-4">
                                                            <button disabled={loading} className={`p-[4px] rounded-[4px] ${ loading ? 'bg-disabled' : 'bg-primary'}`} onClick={() => handleDelete(item.id)}>Delete</button>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </div>
        </div>
    )
}

export default Contact;
