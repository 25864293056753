import {FaCartPlus} from 'react-icons/fa';
import {useSelector} from 'react-redux';
import {selectCartTotal} from '../../../redux/cart/cart.slice';

function CartIcon() {
    const cartCount = useSelector(selectCartTotal)
    return(
        <div className='relative mr-[24px] xl:mr-[12px]'>
            {
                cartCount ? <div className='flex absolute bg-primary justify-center items-center text-[12px]
              -top-[12px] left-4 text-light w-[24px] h-[24px] rounded-full'>
                    {`${cartCount.toString().slice(0,1)}${cartCount.toString().length > 1 ? '+' : ''}`}
                </div> : null
            }
            <FaCartPlus className='text-primary text-[24px] cursor-pointer '/>
        </div>
    )
}

export default CartIcon;
